.App-sidebar {
  display: flex;
  flex-direction: column;
  width: 350px;
  background-color: #121212;
  color: white;
  padding: 20px;
  align-items: center;
  background-image: url('../../assets/sideBar-bg.png');
}

.logo-section {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 15px;
  font-weight: 300;
  margin-top: 15px;
}

.list-section {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  padding: 0;
  text-transform: none;
  font-size: 16px;
  cursor: pointer;
}

.dropbtn {
  display: flex;
  /* background-color: #121212; */
  color: white;
  border: none;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  padding-left: 0;
  margin-bottom: 10px;
  font-weight: 600;
}

.dropbtn img {
  margin-left: 55px;
}

.dropdown-content {
  margin-left: 20px;
  margin-top: 10px;
}

.dropdown-content a {
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}

.simple-link {
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}

.hamburger {
  background: none;
  border: none;
  color: red;
  font-size: 24px;
  cursor: pointer;
  display: none; /* Hidden by default */
  position: absolute;
  top: 20px;
  left: 10px;
}

@media (max-width: 800px) {
  .hamburger {
    display: block; /* Show only on small screens */
    color: rgb(241, 0, 0);
    right: 20;
  }

  .App-sidebar {
    padding: 20px;
    position: fixed;
    left: 300px;
    top: 0;
    align-items: center;
    height: 100vh;
    width: 270px; /* Sidebar width */
    transform: translateX(-100%); /* Start hidden off screen */
    transition: transform 0.3s ease;
  }

  .App-sidebar.open {
    transform: translateX(0); /* Slide in */
  }
}

.active {
  /* background-color: rgb(78, 77, 77); */
  padding: 5px 10px;
  /* border-radius: 10px; */
  background-color: #D11C1C;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.profile-link-container {
  margin-top: auto;  /* Pushes to the bottom */
  position: fixed;
    width: 100%;
    /* left: 50%; */
    bottom: 60px;
    height: 20px;
    /* font-size: 3rem; */
    z-index: 1;
    cursor: pointer;
    /* color: green; */
}