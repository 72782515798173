/* Styles for the entire table container */
.tableContainer {
    font-family: Arial, sans-serif; /* Setting a common font */
    font-size: 16px;
    color: #333;
    border-collapse: collapse;
    background-color: #000;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 100vh;
  }
  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 18px;
    margin-right: 18px;
  }
  .filterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 13px;
    margin-bottom: 10px;
  }
  .filterButton{
      font-size: 18px;
      border: none;
      font-weight: 500;
  }
  .tableScrollable {
    max-height: 30vh; 
    overflow-y: auto; 
    overflow-x: hidden;
    width: 99%; 
  }
  .tableScrollable::-webkit-scrollbar {
    width: 8px;
    height: 8px; 
  }
  .tableScrollable::-webkit-scrollbar-thumb {
    background-color: #BCBCC6;
    border-radius: 10px; 
    border: 2px solid #EEEEF2; 
  }
  

  .tableScrollable::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px; 
    box-shadow: inset 0 0 5px #BCBCC6 
  }
  
  .tableScrollable::-webkit-scrollbar-button {
    display: none; 
  }
  .headerTitle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  .iconbutton {
    border: none;
    cursor: pointer;
    background-color: #000;
  }
  /* Styles for the table header */
  th {
    background-color: #242424;
    color: #333;
    font-weight: 700;
    text-align: left;
    padding: 8px 10px;
    border: 1px solid #EEEEF2;
    border-style: solid;
  }
  
  /* Styles for table data cells */
  td {
    padding: 8px 10px;
  }
  
  /* Header of the table */
  th:first-child,
  td:first-child {
    border-left: none;
  }
  
  th:last-child,
  td:last-child {
    border-right: none;
  }
  .search-box {
    display: flex;
    align-items: center;
    position: relative;
    width: 140px;  /* You can adjust this width as needed */
}

.search-box input[type="text"] {
    width: 100%;
    padding-right: 30px;  /* Adjust padding to make room for the button inside the input */
    border: none;
    background-color: #E9E8FC;
    padding: 11px;
    border-radius: 50px;
    align-items: center;
    font-size: 16px;
    padding-left: 15px;
    outline: none;
}

.search-icon {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    height: 100%;
    margin-right: 10px;
    color: black;
}


.modalButton {
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    background-color: #E9E8FC;
    border-radius: 50px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}
.button-pair {
  display: flex;
  gap: 10px;
}



@media (max-width: 1024px) {
  .tableContainer {
    overflow-x: auto; /* Enables horizontal scrolling if table is too wide */
    width: 100%;
  }
}