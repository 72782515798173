.tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-box {
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    color: #fff;
    background-color: #000;
    border-radius: 6px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
    z-index: 1000;
    white-space: nowrap;
    /* border: 2px solid #D11C1C; */
  }
  
  .tooltip-box::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
    filter: drop-shadow(0 -3px 3px rgba(0,0,0,0.1));
  }
  
  .tooltip-item {
    margin-bottom: 4px;
  }
  
  .tooltip-item:last-child {
    margin-bottom: 0;
  }
  