.card-main {
    background-color: #1b1b1b;
    padding: 20px 40px;
    margin: 20px;
    width: 90%;
}
.card-button {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
}
.top-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}
.statusBtn {
    background-color: #D11C1C;
    border: none;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #fff;
    padding: 5px 20px;
    text-align: center;
    margin-top: 30px;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Ensures the header stretches full width */
  }
  
.headerTitle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-left: 40px;
    justify-content: space-between;
  }
  .iconbutton {
    border: none;
    cursor: pointer;
    /* background-color: #000; */
  }

.filter-main {
    display: flex;
    flex-direction: row;
}
  .search-box {
    display: flex;
    align-items: center;
    position: relative;
    width: 200px;  /* You can adjust this width as needed */
    margin-left: 30px;
}