.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D11C1C;
  color: #fff;
  padding: 20px;  
}
.update-section {
  display: flex;
  gap: 5px;
  align-items: center;
}
.update-section > button {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.user-info span,
.user-info button {
  margin-right: 10px;
  align-items: center;
}

.user-info button {
  background-color: transparent;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 800px) {
  .update-section {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-left: 40px;
  }
}
@media (max-width: 425px) {
  .App-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    background-color: #ededed;
    color: black;
    padding: 30px;  
  }
  .update-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}