.network-container {
  background-color: #000;
  padding: 10px;
}

.heading-section {
  padding: 10px;
  font-weight: 600;
}
.radio-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.radio-container > label {
  align-items: center;
}
.labelStyle {
  margin-left: 7px;
  font-weight: 600;
}
.container-1 {
  display: flex;
  flex-direction: row;
  gap: 70px;
  width: 50%;
}

.input-label {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.input {
  width: 500px;
  height: 35px;
  border-radius: 50px;
  padding: 0px 5px;
  border: 0.5px solid #D11C1C;
  background-color: #121212;
  outline: none;
  color: #fff;
}
.input-container {
  display: flex;
  flex-direction: row;
  gap: 120px;
  margin-bottom: 15px;
}

.container-2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: 50%;
}
.container-2 > button {
  background-color: #D11C1C;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  width: 100px;
  border-radius: 5px;
  height: 35px;
  margin-top: 30px;
  cursor: pointer;
  text-align: center;
}

.connection-container {
  padding: 20px;
  border-top: 1px solid black;
}
.connection-detail {
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: #000;
  width: 800px;
  border: 1px solid #fff;
  height: 300px;
}
.interface{
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.arr-button{
  border: none;
  background-color: transparent;
  border: none;
  outline: none;
}