/* Styles for the entire table container */
.tableContainer {
  font-family: Arial, sans-serif; /* Setting a common font */
  font-size: 16px;
  color: #333;
  border-collapse: collapse;
  background-color: #000;;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 100vh;
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#tdReadBg {
  background-color: rgb(228, 64, 64) !important
}
#thReadBg {
  background-color: rgb(228, 64, 64) !important
}
.filterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 13px;
  margin-bottom: 10px;
}
.filterButton{
    font-size: 18px;
    border: none;
    font-weight: 500;
}
.headerTitle {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.iconbutton {
  border: none;
  cursor: pointer;
  background-color: #000;
}
/* Styles for the table header */
th {
  color: #333;
  font-weight: 700;
  text-align: left;
  padding: 15px;
}

/* Styles for table data cells */
td {
  padding: 12px;
  font-size: 14px;
  width: 70px;
  box-sizing: border-box;
  border: 1px solid #EEEEF2;
}

/* Header of the table */
/* th:first-child,
td:first-child {
  border-left: none;
}

th:last-child,
td:last-child {
  border-right: none;
} */
.table-scrollable {
  max-height: 85vh; 
  overflow-y: auto; 
  overflow-x: hidden;
  width: 99%; 
}
.table-scrollable::-webkit-scrollbar {
  width: 8px;
  height: 8px; 
}
.table-scrollable::-webkit-scrollbar-thumb {
  background-color: #BCBCC6;
  border-radius: 10px; 
  border: 2px solid transparent; 
}


.table-scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px; 
  box-shadow: inset 0 0 5px #BCBCC6 
}

.table-scrollable::-webkit-scrollbar-button {
  display: none; 
}

@media (max-width: 786px) {
  .tableContainer {
    overflow-x: auto; /* Enables horizontal scrolling if table is too wide */
  }
}