/* Styles for the entire table container */
.tableContainer {
  font-family: Arial, sans-serif; /* Setting a common font */
  font-size: 16px;
  color: #333;
  border-collapse: collapse;
  background-color: #000;
  border: none;
  height: 100vh;
}
.headerContainer {
  padding: 15px;
  font-weight: 600;
  background-color: #D11C1C;
  margin: 10px 0px;
  color: #fff;
}
.tableScrollable {
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 98%;
  margin-left: 15px;
}
.tableScrollable::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.tableScrollable::-webkit-scrollbar-thumb {
  background-color: #bcbcc6;
  border-radius: 10px;
  border: 2px solid transparent;
}

.tableScrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
  box-shadow: inset 0 0 5px #bcbcc6;
}

.tableScrollable::-webkit-scrollbar-button {
  display: none;
}

.iconbutton {
  border: none;
  cursor: pointer;
  background-color: transparent;
}
/* Styles for the table header */
th {
  color: #fff;
  font-weight: 700;
  text-align: left;
  padding: 8px 10px;
  border: 5px solid #000 !important;
}

/* Styles for table data cells */
td {
  padding: 8px 10px;
}

/* Header of the table */
th:first-child,
td:first-child {
  border-left: none;
}

th:last-child,
td:last-child {
  border-right: none;
}
.search-box {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%; /* You can adjust this width as needed */
}

.search-box input[type="text"] {
  width: 100%;
  padding-right: 30px; /* Adjust padding to make room for the button inside the input */
  border: 0.5px solid #D11C1C;
  background-color: #000;
  padding: 11px;
  border-radius: 50px;
  align-items: center;
  font-size: 16px;
  padding-left: 15px;
  outline: none;
  color: #fff;
}

.search-icon {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  height: 100%;
  margin-right: 10px;
  color: black;
}

.modalButton {
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  background-color: #D11C1C;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
}
.button-pair {
  display: flex;
  gap: 10px;
}

@media (max-width: 1024px) {
  .tableContainer {
    overflow-x: auto; /* Enables horizontal scrolling if table is too wide */
    width: 100%;
  }
}
