/* Styles for the entire table container */
.tableContainer {
    font-family: Arial, sans-serif; /* Setting a common font */
    font-size: 16px;
    color: #333;
    border-collapse: collapse;
    background-color: #000;;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    height: 100vh;
  }
  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  #tdReadBg {
    background-color: rgb(228, 64, 64) !important
  }
  #thReadBg {
    background-color: rgb(228, 64, 64) !important
  }
  .filterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 13px;
    margin-bottom: 10px;
  }
  .filterButton{
      font-size: 18px;
      border: none;
      font-weight: 500;
  }
  .headerTitle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
  .iconbutton {
    border: none;
    cursor: pointer;
    /* background-color: #000; */
  }
  /* Styles for the table header */
  th {
    color: #333;
    font-weight: 700;
    text-align: left;
    padding: 15px;
  }
  
  /* Styles for table data cells */
  td {
    padding: 12px;
    font-size: 14px;
    width: 70px;
    box-sizing: border-box;
    border: 1px solid #EEEEF2;
  }
  
  /* Header of the table */
  /* th:first-child,
  td:first-child {
    border-left: none;
  }
  
  th:last-child,
  td:last-child {
    border-right: none;
  } */
  .table-scrollable {
    max-height: 85vh; 
    overflow-y: auto; 
    overflow-x: hidden;
    width: 99%; 
  }
  .table-scrollable::-webkit-scrollbar {
    width: 8px;
    height: 8px; 
  }
  .table-scrollable::-webkit-scrollbar-thumb {
    background-color: #BCBCC6;
    border-radius: 10px; 
    border: 2px solid transparent; 
  }
  
  
  .table-scrollable::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px; 
    box-shadow: inset 0 0 5px #BCBCC6 
  }
  
  .table-scrollable::-webkit-scrollbar-button {
    display: none; 
  }
  
  @media (max-width: 786px) {
    .tableContainer {
      overflow-x: auto; /* Enables horizontal scrolling if table is too wide */
    }
  }

  .admin-form {
    /* max-width: 800px; */
    margin: 20px auto;
    background-color: #000;
    padding: 20px;
    box-shadow: 0 0 10px rgba(245, 21, 21, 0.1);
    width: 100%;
  }
  
  .profile-picture img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* display: block; */
    margin: 10px auto 20px;
    /* justify-content: flex-start; */
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: 20px;
    line-height: 1.5;
    color: #fff;
    font-weight: 800;
  }
  
  .form-control {
    background-color: transparent;
    border: 0.5px solid #dee2e6;
    color: #000;
  }
  
  .btn-primary {
    background-color: #D11C1C;
    border-color: #D11C1C;
    border-radius: 0px;
    margin-bottom: 20px;
    padding: 5px 10px;
  }
  
  .text-center {
    text-align: center;
  }
  .nameEmail {
    flex-direction: column;
    margin: 10px auto 20px;
  }
  .account{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 5px solid #242424;
    margin-bottom: 50px;
  }
  .form-control::placeholder {
    color: #fff;
    opacity: 1;
  }


  