.heks-main {
    display: flex;
    flex-direction: row;
    width: 7%;
    justify-content: space-between;
    align-items: 'center';
    margin-left: 20px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-top: 8px;
}

 p {
    font-weight: bold;
}

.multicolor-bar {
    margin: 20px 20px;
    width: 30%;
}

.multicolor-bar .values .value,
.multicolor-bar .scale .graduation,
.multicolor-bar .bars .bar {
    float: left;
    text-align: center;
}

.multicolor-bar .bars .bar {
    height: 10px;
}

.multicolor-bar .bars div.bar:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.multicolor-bar .bars div.bar:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.multicolor-bar .legends .legend {
    display: inline-block;
    margin: 0 5px;
}

.multicolor-bar .legends .legend .dot {
    font-size: 25px;
    vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
    margin-left: 2px;
    vertical-align: middle;
}
