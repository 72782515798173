.card-main {
    background-color: #1b1b1b;
    padding: 20px 40px;
    margin: 20px;
    width: 73%;
}
.card-button {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
}

.statusBtn {
    background-color: #D11C1C;
    border: none;
    outline: none;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    color: #fff;
    padding: 5px 20px;
    text-align: center;
    margin-top: 30px;
}
.ticket-info-bedge {
  width: 75px;
  margin-top: 30px;
  height: 30px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 2px;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Ensures the header stretches full width */
  }
  
.headerTitle {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-left: 40px;
    justify-content: space-between;
  }
  .iconbutton {
    border: none;
    cursor: pointer;
    /* background-color: #000; */
  }

.filter-main {
    display: flex;
    flex-direction: row;
}
  .search-box {
    display: flex;
    align-items: center;
    position: relative;
    width: 200px;  /* You can adjust this width as needed */
    margin-left: 30px;
}

.top-content {
    /* display: flex; */
    flex-direction: row;
    /* justify-content: space-between; */
    /* margin-bottom: 40px; */
}

.admin-message {
    background-color: #fff;
    padding: 10px 20px;
    width: 80%;
    /* margin-left: -30px; */
}

.toggle-switch {
  margin-top: 30px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #D11C1C;
}

input:focus + .slider {
  box-shadow: 0 0 1px #D11C1C;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.row {
  display: flex;
  flex-direction: row;
  /* border-left: 5px solid #fff; */
}
.chat-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.date-header {
  text-align: center;
  font-weight: bold;
  margin: 10px 0;
  color: #fff;
}

.message-container {
  max-width: 70%;
  margin: 10px 0;
  padding: 10px;
  /* border-radius: 10px; */
}

.sent {
  background-color: #D11C1C;
  align-self: flex-end;
  display: 'flex'; 
  align-items: center;
  /* margin: 10px 0px;  */
  margin-left: 350px
}

.received {
  background-color: #fff;
  align-self: flex-start;
}

.sent-timestamp {
  font-size: 12px;
  color: rgb(255, 247, 247);
  text-align: right;
}
.received-timestamp {
  font-size: 12px;
  color: rgb(0, 0, 0);
  text-align: right;
}

.icons-modal {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}
.sent-message {
  color: #fff;
  font-weight: 600;
}
.received-message {
  color: #000;
  font-weight: 600;
}
.delete-btn {
  background-color: #D11C1C;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
}
.close-btn {
  background-color: transparent;
  color: #D11C1C;
  border: none;
  outline: none;
  margin-left: 10px;
  font-weight: 600;
}