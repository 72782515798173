.permission-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  background-color: #000;
}
.main-heading {
  font-size: 25px;
  font-weight: 600;
}
.permissions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
}
.permission-detail {
  display: flex;
  flex-direction: column;
}
.detail-heading {
  font-size: 20px;
}
.detail-desc {
  font-size: 15px;
  text-wrap: wrap;
}

.toggle-switch {
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch.on {
  background-color: #4caf50;
}

.toggle-thumb {
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  margin-left: 1px;
  transition: margin-left 0.3s;
}

.toggle-switch.on .toggle-thumb {
  margin-left: 24px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.permission-button {
  width: 150px;
  padding: 20px;
  background-color: #644ded;
  border-radius: 5px;
  border: none;
  font-size: large;
  color: white;
  cursor: pointer;
}
